<!-- 首页 -->
<template>
  <div class="index_page">
    <!-- 左边菜单区 -->
    <div class="right_box">
      <div style="width: 100%; height: 100%; display: flex;">
        <div class="tab_list" v-if="!isShowSearch">
          <div class="tab_btn ft-bold" style="font-size: 2.6rem;">分类({{ categoryList.length }})</div>
          <div class="tab-list-box">
            <div v-for="(item, index) in categoryList" :key="index" class="tab_btn one-line-ellipsis" :class="{ 'choose bg-e7f3ff': categoryId == item.id }" @click="chooseClassify(item)">{{ item.name }}</div>
          </div>
        </div>
        <div class="good-box" v-if="!isShowSearch">
          <div class="good-title-box">
            <div class="cate-name">{{ category_name }}({{ total }})</div>
            <div class="flex flex-ac">
              <button class="search-btn-tag bg-1588F5 mg-rt20" @click="openSearch"><van-icon name="search" color="#fff" size="2.8rem" class="mg-rt5" />搜索</button>
              <button class="search-btn-tag bg-3DB956" @click="openCustomGood"><van-icon name="gold-coin-o" color="#fff" size="2.8rem" class="mg-rt5" />定制商品</button>
            </div>
          </div>
          <div class="index_list">
            <div v-for="(item, i) in goodsList" :key="i" class="index_box" @click="setShopCart(item, 1)">
              <img :src="item.img" class="index_img" />
              <div>
                <div class="goodsName">{{ item.name }}</div>
                <div class="goodsstock" v-if="item.pro_type == 1">库存：{{ item.stock }}{{ item.dw }}</div>
                <div class="goodsPrc">售价：¥{{ item.fact_price }}</div>
                <div class="goodsPrc" v-if="item.member_price && item.member_price > 0">会员价：¥{{ item.member_price }}</div>
              </div>
              <!-- <div class="icon-con">
                <img src="../../static/online.png" class="icon" v-if="item.online === 1" />
                <img src="../../static/kucun.png" class="icon" v-if="item.dw == 'kg'" />
              </div> -->
            </div>
            <!-- <div class="addGoods" @click="goToManageProducts()">
              <span class="ft_30">+</span> 管理商品
            </div> -->
          </div>
          <div class="flex flex-ac flex-je pagination-box">
            <van-pagination class="mg-rt20" v-model="params.page" :total-items="total" :items-per-page="params.limit" @change="pageChange" force-ellipses />
          </div>
        </div>

        <!-- 搜索 -->
        <div class="search-good-box" v-if="isShowSearch">
          <titlebar name="index-search" :is_custom_back="true" @goBackCustom="closeSearch" />
          <div class="flex" style="margin: 2rem;">
            <input class="screeninput" style="text-align: left" placeholder="输入商品名称或助记码搜索" v-model="params.goodsName" ref="mn_codeinput" />
            <button class="screenbtn" @click="handleSearch()">搜索</button>
          </div>
          <div class="index_list">
            <div v-for="(item, i) in searchDishList" :key="i" class="index_box" @click="setShopCart(item, 1)">
              <img :src="item.img" class="index_img" />
              <div>
                <div class="goodsName">{{ item.name }}</div>
                <div class="goodsstock" v-if="item.pro_type == 1">库存：{{ item.stock }}{{ item.dw }}</div>
                <div class="goodsPrc">售价：¥{{ item.fact_price }}</div>
                <div class="goodsPrc" v-if="item.member_price && item.member_price > 0">会员价：¥{{ item.member_price }}</div>
              </div>
              <!-- <div class="icon-con">
                <img src="../../static/online.png" class="icon" v-if="item.online === 1" />
                <img src="../../static/kucun.png" class="icon" v-if="item.dw == 'kg'" />
              </div> -->
            </div>
            <!-- <div class="addGoods" @click="goToManageProducts()">
              <span class="ft_30">+</span> 管理商品
            </div> -->
          </div>
          <div class="flex flex-ac flex-je pagination-box">
            <van-pagination class="mg-rt20" v-model="params.page" :total-items="search_total" :items-per-page="params.limit" @change="pageChangeSearch" force-ellipses />
          </div>
        </div>
      </div>
    </div>
    <!-- 右边操作区 -->
    <div class="left_box">
      <!-- 菜单    搜索 -->
      <div class="left_heard">
        <!-- 扫描枪扫描商品 -->
        <input v-if="!is_adding" v-model="proCode" ref="again" autofocus @keyup.enter="getProByCode(proCode, 1, $event)" @focus="noKeybord" placeholder="请先选中输入框后进行扫描" class="scancode-input" />
        <input v-if="is_adding" placeholder="请先选中输入框后进行扫描" class="scancode-input" />
      </div>
      <!-- <div class="search-box">
        <div class="search-text">商品搜索</div>
        <input v-model="params.goodsName" autofocus @keyup.enter="handleSearch" placeholder="请输入商品名称搜索" class="scancode-input" style="flex: 1;" />
      </div> -->
      <!-- 商品区 -->
      <div class="left_body" ref="cart_box">
        <div class="vip-info">
          <div class="vip-info-left" v-if="Object.keys(vipInfo).length">
            <div>会员手机号：{{ vipInfo.phone }}</div>
            <div>会员余额：{{ vipInfo.balance }}</div>
            <!-- <div>银行余额：{{ vipInfo.bank_amount }}</div> -->
            <div>会员折扣：{{ vipInfo.member_type }}</div>
          </div>
          <div v-else>未选择会员</div>
          <div class="vip-handle">
            <van-button color="#999" v-if="Object.keys(vipInfo).length" style="margin-left: 0.4rem" @click="$store.commit('setVipInfo', {})">清空</van-button>
            <van-button color="#1588f5" v-else @click="openMemModal()">选择会员</van-button>
          </div>
        </div>
        <div class="goods" v-for="(item, index) in shopCartList" :key="index" :class="{ createborder: index == 9 }" :ref="'cart_' + item.g_id">
          <div class="goods_title">
            <div v-if="item.is_editprice == 1" class="change-tag">改</div><span class="va-middle ft-bold">{{ item.g_name }}</span>
          </div>
          <div class="goods_index">
            <div class="goods_prc">
              小计:¥{{
                $bigN(item.fact_price).times(item.num).div(100).toNumber().toFixed(2)
              }}
              <van-icon name="edit" size="2.4rem" color="#1588f5" class="mg-lt10" @click.stop="openChangePrice(item)" />
            </div>
            <div class="goods_steper" :class="{
                'chengzhong-steper': item.dw == '千克' || item.dw == '斤' || item.dw == '克',
              }">
              <van-stepper v-if="item.pro_type == 1" :disabled="!overTime" v-model="item.num" :step="0" theme="round" button-size="22" async-change :max="item.type == 2 ? item.num : item.stock" :min="item.dw == 'kg' || item.dw == '千克' || item.dw == '斤' || item.dw == '克' ? item.num : 1" disable-input @plus="addNum(item, index)" @minus="plusNum(item)" @overlimit="deleteGoods(item, index, $event)" />
              <van-stepper v-if="item.pro_type == 2 || item.pro_type == 3" :disabled="!overTime" v-model="item.num" :step="0" theme="round" button-size="22" async-change :min="1" disable-input @plus="addNum(item, index)" @minus="plusNum(item)" @overlimit="deleteGoods(item, index, $event)" />
              <div class="goods_dw">{{ item.dw }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 结算区 -->
      <div class="left_foot">
        <!-- 如果列表中有商品显示商品价格,清空挂单按钮 -->
        <div v-if="shopCartList.length > 0">
          <!-- 价格区 -->
          <div class="foot_prc">
            <div style=" display: flex;justify-content: space-between;">
              <div class="foot_num">
                数量:<span class="color_1ccfcf">{{ totalCount }}</span>
              </div>
              <div class="foot_total">
                <!-- <div class="foot_box_total">
                                <div class="foot_txt">合计:</div>
                                <div>¥{{ totalPrice }}</div>
                            </div> -->
                <!-- <div class="foot_box_total">
                                <div class="foot_txt">优惠:</div>
                                <div class="color_1ccfcf">¥115.00</div>
                            </div> -->
                <div class="foot_box_total">
                  <div class="foot_txt">合计金额:</div>
                  <div class="color_1ccfcf">¥{{ vipInfo.discount ? $bigN(totalPrice).times(vipInfo.discount).div(10).toNumber().toFixed(2) : totalPrice }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="member-price">会员价：¥{{totalMemberPrice}}</div> -->
          </div>
          <!-- 清空    挂单 -->
          <div class="btn_box">
            <van-button class="prc_btn" @click="clearlist">
              清空({{ totalCount }})
              <!-- {{ areCancellOrderNum == "" ? "" : "追加" }}       -->
            </van-button>
            <van-button class="prc_btn border_1ccfcf" @click="areCancell()">挂单</van-button>
          </div>
        </div>
        <!-- 如果列表中无商品显示取单 -->
        <div v-else class="border_top">
          <van-button class="areCancell" @click="gotoTakeOrders()">取单</van-button>
        </div>
        <!-- 收银按钮 -->
        <van-button class="cashOut" :class="{ 'cashOut-gray': shopCartList.length == 0 }" @click="goTosettlement()">
          <span>收银</span>
          <!-- <span>¥{{ vipInfo.discount ? $bigN(totalPrice).times(vipInfo.discount).div(10).toNumber().toPrice() : totalPrice }}</span> -->
        </van-button>
        <!-- <van-button
          class="cashOut"
          @touchstart="getTouchStart"
          @touchend.native.prevent="getTouchEnd"
        >
          <span class="pr_30">打开钱箱</span>
          <span></span>
        </van-button> -->
      </div>
    </div>
    <!-- 页面的单个DIV结束 -->

    <van-popup v-model="vipModal" :close-on-click-overlay="false" @closed="vipInfoShow = []; vipTel = ''; codeInputFocus();" :round="true">
      <div class="vip-modal">
        <div class="vip-modal-head">
          <div class="vip-modal-head-title">会员选择</div>
          <img src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png" @click="vipModal = false" />
        </div>
        <div class="vip-modal-body">
          <div class="vip-modal-body-input">
            <input v-model="vipTel" placeholder="手机号或姓名搜索" ref="membercodeinput" @keyup.enter="vipTelSearch" />
            <van-button color="#1588f5" @click="vipTelSearch">搜索</van-button>
          </div>
          <div class="vip-modal-body-info" v-if="vipInfoShow.length > 0">
            <div v-for="(item, index) in vipInfoShow" class="vipinformation flex flex-jsb flex-ac" :class="{'choosed1': choose_index == index,}" @click="confirmMember(index)">
              <div>
                <div class="mg-bt15">{{ item.name }}</div>
                <div>{{ item.phone }}</div>
              </div>
              <div>￥{{ item.balance }}</div>
            </div>
          </div>
          <div class="vip-modal-body-info" v-if="is_show_notdata">
            <div class="nullStyle2">
              <p class="text-center ft-24 color-999">抱歉，暂无搜索结果</p>
              <img src="@/static/images/zanwu.png" alt="" />
            </div>
          </div>
          <div style="height: 9rem;"></div>
          <!-- 确认/取消 -->
          <div class="btns flex flex-jc">
            <button class="cancel" @click="vipModal = false">取消</button>
            <button class="confirm" @click="toAddMem">新增会员</button>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 称重商品获取重量 -->
    <van-popup v-model="is_show_weight" :close-on-click-overlay="false" @open="openGoodsWeightDialogNew" @close="goodsWeightDialogCloseNew" style="top: 40%;">
      <div class="chengzhong-popups">
        <div class="system_title">
          <div v-if="isInApp == 'android' && (!scale_type || scale_type == '')" class="flex flex-ac ft-20 scale-cont-box" @click.stop="openChooseScale">
            <van-icon name="warning" size="2.5rem" color="#e60012" />
            <span class="mg-lt5 mg-rt5 color-e60012">未选择电子秤</span>
            <van-icon name="edit" size="2.5rem" color="#1588f5" />
          </div>
          <div v-if="isInApp == 'android' && scale_type && scale_type != ''" class="flex flex-ac ft-20 scale-cont-box" @click.stop="openChooseScale">
            <span class="mg-lt5 mg-rt5">电子秤:{{ scale_type | ScaleType }}</span>
            <van-icon name="edit" size="2.5rem" color="#1588f5" />
          </div>
          <div style="flex: 1;">称重</div>
          <img src="@/static/images/close.png" @click="is_show_weight = false; codeInputFocus();" />
        </div>

        <div class="cheng-status" v-if="weightDetail.overflow && weightDetail.overflow != ''">
          <div class="ft-38">溢出状态</div>
          <div class="scale">
            <span v-if="weightDetail.overflow == 0">没有溢出，可以称重</span>
            <span v-if="weightDetail.overflow == 1">已经溢出，无法称重</span>
          </div>
        </div>

        <div class="scale-price">
          <div>
            <span class="scale-label">名称：</span>
            <span class="scale-text">{{ goodsItem.name }}</span>
          </div>
          <div>
            <span class="scale-label">价格：</span>
            <span class="scale-text">￥{{ goodsItem.fact_price }}</span>
            <span>/{{ goodsItem.dw }}</span>
          </div>
          <div>
            <span class="scale-label ft-36">计重：</span>
            <input type="number" class="scale-text ft-40 ft-bold" v-model="goodsNetWeight" style="border-bottom: 0.02rem solid #cccccc; width: 24rem;" @input="handleWeightChange" />
            <span>千克</span>
            <span v-if="goodsItem.dw != '千克'">（{{weightConversion}}{{goodsItem.dw}}）</span>
          </div>
          <div>
            <span class="scale-label">金额：</span>
            <span class="scale-text ft-40 ft-bold">{{ goodsWeightPrice }}</span>
            <span>元</span>
          </div>
        </div>
        <div class="bottom-btn">
          <div class="but mg-rt30" style="background: #EAEAEA; color: #666666;" @click="printWeightTag">打印标签</div>
          <div v-if="isInApp == 'android' || isInApp == 'mt_android'" class="but" style="background: #3DB956" @click="clearWeightNew(1)">
            <van-loading v-if="isShowPeelLoading" color="#fff" size="3.2rem" class="mg-rt10" />
            去皮
          </div>
          <div v-if="isInApp == 'android' || isInApp == 'mt_android'" class="but mg-lt30" style="background: red" @click="clearWeightNew(2)">
            <van-loading v-if="isShowZeroLoading" color="#fff" size="3.2rem" class="mg-rt10" />
            置零
          </div>
          <div class="but mg-lt30" @click="confirmDet">确认</div>
        </div>
      </div>
    </van-popup>

    <popupOfDialog v-if="showDialog" :type="dialogType" :title="dialogTitle" :message="dialogMsg" :data="dialogData" @closePopDialog="closePopDialog" @confirmPopDialog="confirmPopDialog"></popupOfDialog>

    <div class="choose-scale-modal" v-if="show_choose_scale">
      <div class="modal-content-box relative">
        <img src="@/static/images/close.png" class="close" alt srcset @click="closeScale" />
        <div class="wrapper">
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">选择电子秤</div>
          <div class="choose-scle-box">
            <van-radio-group v-model="choose_scale">
              <van-radio name="1" icon-size="3rem" class="scale-radio">和成东</van-radio>
              <van-radio name="2" icon-size="3rem" class="scale-radio">商米</van-radio>
              <!-- <van-radio name="3" icon-size="3rem" class="scale-radio">美团</van-radio> -->
              <van-radio name="4" icon-size="3rem" class="scale-radio">一敏</van-radio>
              <van-radio name="5" icon-size="3rem" class="scale-radio">容大</van-radio>
            </van-radio-group>
            <button class="clear-scale" @click="choose_scale = '';">清空选择</button>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeScale">取消</button>
          <button class="confirm " @click="confirmScale">保存</button>
        </div>
      </div>
    </div>

    <div class="choose-scale-modal change-price-modal" v-if="show_change_price">
      <div class="modal-content-box relative">
        <img src="@/static/images/close.png" class="close" alt srcset @click="closeChangePrice" />
        <div class="wrapper">
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">修改单价</div>
          <div class="choose-scle-box">
            <div class="di-box mg-bt20">
              <div class="dilabel">单价：</div>
              <input v-if="platform_name == 'android'" class="diinput" v-model="change_price" ref="change_price_input" readonly @click="openKeyboard('change_price')" />
              <input v-else type="number" class="diinput" v-model="change_price" ref="change_price_input" min="0" />
            </div>
            <div class="ft-20 mg-lt90 mg-bt20">*注：商品改价后不享受会员价</div>
            <div class="ft-20 mg-lt90 color-e60012" v-if="c_p_error != ''">{{ c_p_error }}</div>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeChangePrice">取消</button>
          <button class="confirm " @click="confirmChangePrice">确认</button>
        </div>
      </div>
    </div>

    <div class="choose-scale-modal custom-good-modal" v-if="show_custom_good">
      <div class="modal-content-box relative">
        <img src="@/static/images/close.png" class="close" alt srcset @click="closeCustom" />
        <div class="wrapper">
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">定制商品</div>
          <div class="choose-scle-box">
            <input v-if="platform_name == 'android'" class="custom-input" v-model="custom_price" ref="custom_price_input" readonly @click="openKeyboard('custom_price')" placeholder="请输入商品价格" />
            <input v-else type="number" class="custom-input" v-model="custom_price" ref="custom_price_input" min="0" placeholder="请输入商品价格" />
            <div class="ft-20 mg-tp20 color-e60012 text-center" v-if="custom_error != ''">{{ custom_error }}</div>
          </div>
        </div>
        <div style="height: 12rem;"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeCustom">取消</button>
          <button class="confirm " @click="confirmCustom">确认</button>
        </div>
      </div>
    </div>

    <van-number-keyboard v-model="numVal" :title="numVal" :show="show_keyboard" extra-key="." @blur="show_keyboard = false;" @input="handleNumChange" @delete="handleNumDelete" class="otherEatNum-nk" />

    <!-- 扫码添加商品 有多个商品时 -->
    <div class="choose-goods-modal" v-if="show_choose_goods">
      <div class="modal-content-box relative">
        <img src="@/static/images/close.png" class="close" alt srcset @click="show_choose_goods=false" />
        <div class="wrapper">
          <!-- 标题 -->
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">该条码下有多个商品</div>
          <div class="choose-goods-box">
            <div class="box-title">
              <div class="title-text">图片</div>
              <div class="title-text">名称</div>
              <div class="title-text">库存</div>
              <div class="title-text">售价/会员价</div>
              <div class="title-text">助记码</div>
              <div class="title-text">操作</div>
            </div>
            <!-- 商品列表 -->
            <div class="goods-item" v-for="(item, index) in chooseGoodsList" :key="index">
              <div>
                <img :src="item.img" style="width:10rem;height:10rem;" />
              </div>
              <div style="word-wrap:break-word;word-break:break-all;">{{ item.name }}</div>
              <div>{{ item.stock }}{{ item.dw }}</div>
              <div>¥{{ item.fact_price }}{{`${item.member_price*1?'/'+item.member_price:''}`}}</div>
              <div style="word-wrap:break-word;word-break:break-all;">{{ item.code }}</div>
              <button class="select-btn bg-1588F5 mg-rt20" @click="show_choose_goods=false;setShopCart(item, 1)">选择</button>
            </div>
          </div>
        </div>
        <div style="height: 3rem;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import { mapGetters, mapState } from "vuex";
import settlementVue from "./settlement.vue";

import { accMul, accAdd, subTr } from "../../utils/floatComputer";
import titlebar from "@/views/component/titlebar";
import { connectSunMiScaleService, deviceInfo, doTaring, getScaleData, getScaleDataMt, getScaleDateHcd, getScaleDataRd, getScaleDateSm, getScaleInfo, initHcdElectronic, initRdElectronic, initScale, isScaleSupported, peelScale, removeScaleListener, sunMiClose, sunMiScaleDataInfo, sunMiTurnTare, sunMiTurnZero, zeroSettScale } from '../../utils/outPrint';
import { checkPrintType, formatTime, priceUnitConversion, weightUnitConversion } from '../../utils/util';

import popupOfDialog from "../component/popupOfDialog.vue";

export default {
  data() {
    return {
      chooseGoodsList: [], // 选择商品列表
      show_choose_goods: false,//控制选择商品弹框
      menuopen: false, //控制弹窗的变量
      // settlementShow: false, //控制商品列表
      turnoff: false, // 规格弹窗
      popName: "", //控制打开哪个弹窗
      goodsList: [], // 商品列表
      categoryList: [], // 商品分类列表
      categoryId: "", // 选择的商品分类ID
      category_name: "", // 选择的商品分类名称
      // shopCartList: [], // 购物车列表
      proCode: undefined, // 商品条码  测试条码:6928454653828
      c_proCode: undefined, // 返回的商品条码，不显示在搜索框
      t_order: 1, // 1新的订单，2已开始订单
      swee_code: undefined, // 返回的扫码编码，第二个增加的商品开始传
      e_at: 1, // 1增加，2减少
      scanPro: {},
      overStock: false, // 是否超过库存
      overTime: true, // 判断请求是否完成
      selectGoodList: false, // 商品切换时防止右侧添加接口请求过快导致商品添加错误
      goodsWeightDialog: false, // 重量商品的弹框
      goodsWeight: 0, // 商品重量
      radio1: false, // 称的类型
      radio2: false, // 净重
      radio3: false, // 零位
      goodsItem: {
        dw: "",
        name: "",
        fact_price: "",
      }, // 显示给弹框的商品
      addKgBtnIndex: false, // 重量新增
      skin: 0, // 皮的重量
      sufu: 0, // 长按
      width: "",
      showdet: false,
      paymentCode: "",
      list: {},
      money: "",
      vipModal: false, // 会员选择的弹框
      vipTel: '', // 会员选择的手机号
      vipInfoShow: [], // 用于显示再弹框的会员信息
      params: {
        page: 1,
        limit: 20,
        goodsName: ""
      },
      total: 0,
      isShowSearch: false,
      searchDishList: [],
      search_total: 0,
      choose_index: -1,
      is_show_weight: false, // 重量商品的弹框
      goodsWeight: 0, // 商品重量
      radio1: false, // 称的类型
      radio2: false, // 净重
      radio3: false, // 零位
      skin: 0, // 皮的重量
      weightChange: false,
      goodsNetWeight: 0,
      goodsWeightTotalPrice: 0,
      goodsWeightPrice: 0,
      goodWeight: 0,
      is_show_notdata: false,
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
      isShowZeroLoading: false,
      isShowPeelLoading: false,
      priceC: 0,
      scale_type: '',
      show_choose_scale: false,
      choose_scale: '',
      isInApp: localStorage.nowDevice,
      befor_produce: false,
      befor_quality: false,
      show_change_price: false,
      change_good: {},
      change_price: '',
      c_p_error: '',
      show_custom_good: false,
      custom_price: '',
      custom_error: '',
      is_adding: false,
      numberModel: '', // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
    };
  },
  filters: {
    ScaleType(val) {
      let obj = {
        1: '和成东',
        2: '商米',
        3: '美团',
        4: '一敏',
        5: '容大'
      }
      return obj[val] || '';
    },
  },
  computed: {
    ...mapGetters({ shop: 'shop' }),
    // 购物车总数
    totalCount() {
      return this.$store.getters.totalCount;
    },
    // 购物车总额
    totalPrice() {
      return this.$store.getters.totalPrice;
    },
    // 购物车会员价总额
    totalMemberPrice() {
      return this.$store.getters.totalMemberPrice;
    },
    // 购物车列表
    shopCartList() {
      return this.$store.state.shopCartList;
    },
    // 挂单订单编号
    areCancellOrderNum() {
      return this.$store.getters.areCancellOrderNum;
    },
    // 商品唯一编码  ,  挂单所有商品列表  // 电子秤获取的重量
    ...mapState(["currentSwee_code", "WeightDataDetail", "weightDetail"]),
    // 计算净重
    // goodsNetWeight() {
    //   // 小数减法
    //   if (
    //     subTr(
    //       this.WeightDataDetail.net ? this.WeightDataDetail.net / 1000 : 0,
    //       this.skin
    //     ) == 0
    //   ) {
    //     this.radio3 = 3;
    //   } else this.radio3 = false;
    //   return subTr(
    //     this.WeightDataDetail.net ? this.WeightDataDetail.net / 1000 : 0,
    //     this.skin
    //   );
    //   // return this.WeightDataDetail.net / 1000 - this.skin;
    // },
    // 计算总价
    // goodsWeightTotalPrice() {
    //   if (
    //     (this.WeightDataDetail.status == 1 ||
    //       (this.WeightDataDetail.status == 3 &&
    //         this.WeightDataDetail.net >= 0)) &&
    //     this.goodsNetWeight > 0
    //   ) {
    //     this.radio1 = 1;
    //     this.skin > 0 ? (this.radio2 = 2) : (this.radio2 = 3);
    //     // return this.goodsItem.fact_price * this.goodsNetWeight;
    //     // 小数乘法
    //     return this.$bigN(this.goodsItem.fact_price).times(this.goodsNetWeight).toNumber().toPrice()
    //   } else {
    //     this.radio1 = false;
    //     this.radio2 = false;
    //     return 0;
    //   }
    // },
    // 计算小计
    subPrice() { },
    vipInfo() { // 选中的会员信息
      return this.$store.state.vipInfo
    },
    // 计算会员价
    memberPrice() {

    },
    goodRealWeight() {
      let weight = 0;
      if (this.weightChange) {
        weight = this.goodsNetWeight;
      } else {
        weight = parseFloat(this.weightDetail.weight).toFixed(3);
        weight = isNaN(weight) ? 0 : weight;
      }
      return weight;
    },
    weightConversion() {
      if (this.is_show_weight && this.goodRealWeight > 0) {
        return weightUnitConversion(this.goodRealWeight, this.goodsItem.dw);
      } else {
        return 0;
      }
    },
  },
  components: {
    titlebar,
    popupOfDialog,
  },
  async created() {
    // console.log(this.$store);
    if (localStorage.nowDevice == 'android' || localStorage.nowDevice == 'mt_android') {
      deviceInfo();
    } else if (localStorage.nowDevice == 'windows') {
      await this.$device.getLocalIP();
    }
    checkPrintType();
    await this.getCategoryList();
    this.getGoodsList();
    this.swee_code = this.currentSwee_code;
    this.showCartList();
    this.$nextTick(() => {
      this.$refs.again.focus();
    }, 100);
    this.getShopInfo();
  },
  mounted() {
    console.log('this.$store.state.vipInfo', this.$store.state.vipInfo);
    document.addEventListener('click', this.codeInputFocus, true);
  },
  destroyed() {
    document.removeEventListener('click', this.codeInputFocus, true);
    window.clearInterval(this.timer);
  },
  watch: {
    'goodRealWeight': {
      handler(newV, oldV) {
        this.getPrice();
      },
      deep: true
    },
    'weightDetail.weight': {
      handler(newV, oldV) {
        if (!this.weightChange) {
          this.setInputWeight();
        }
      },
      deep: true
    },
    is_adding(newV, oldV) {
      if (!this.is_adding) {
        this.codeInputFocus();
      }
    },
    '$store.state.is_change_model': {
      handler(newV, oldV) {
        this.codeInputFocus();
      },
    }
  },
  methods: {
    toAddMem() {
      this.$router.push({
        name: 'addMember',
        params: { type: 'index' },
        query: {
          name: 'addMember'
        }
      })
    },
    handleNumChange(e) {
      this.numValArr.push(e);
      this.numVal = this.numValArr.join('');
      this[`${this.numberModel}`] = this.numVal;
    },
    handleNumDelete(e) {
      this.numValArr.pop();
      this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
      this[`${this.numberModel}`] = this.numVal;
    },
    openKeyboard(name) {
      console.log(this[`${name}`]);
      this.numberModel = name;
      this.numVal = this[`${name}`] || '';
      this.numValArr = this[`${name}`].split('');
      this.show_keyboard = true;
    },
    closeCustom() {
      this.show_custom_good = false;
      this.$nextTick(() => {
        this.$refs.again.focus();
      }, 100);
    },
    confirmCustom() {
      this.custom_error = '';
      if (!/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.custom_price)) {
        this.custom_error = '必须为数字，小数点后最多两位';
        return;
      }
      let query = {
        price: this.custom_price,
      }
      this.$api.customized(query).then(res => {
        if (res.code == 1) {
          this.changeCart(res.data.d.bar_code, 1);
          this.closeCustom();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    openCustomGood() {
      this.custom_price = '';
      this.show_custom_good = true;
      if (this.platform_name == 'android') {
        this.openKeyboard('custom_price');
      }
      this.$nextTick(() => {
        this.$refs.custom_price_input.focus();
      })
    },
    confirmChangePrice() {
      this.c_p_error = '';
      if (!/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.change_price)) {
        this.c_p_error = '必须为数字，小数点后最多两位';
        return;
      }
      let query = {
        id: this.change_good.g_id,
        swee_code: this.swee_code, // 返回的唯一编码
        price: this.change_price,
      }
      this.$api.editPrice(query).then(res => {
        if (res.code == 1) {
          const show_order = res.data || [];
          this.$store.commit("updateShopCart", show_order); //更新购物车数据
          this.closeChangePrice();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    closeChangePrice() {
      this.show_change_price = false;
      this.$nextTick(() => {
        this.$refs.again.focus();
      }, 100);
    },
    openChangePrice(item) {
      this.change_good = item;
      this.change_price = '';
      this.c_p_error = '';
      this.show_change_price = true;
      if (this.platform_name == 'android') {
        this.openKeyboard('change_price');
      }
      this.$nextTick(() => {
        this.$refs.change_price_input.focus();
      }, 100)
    },
    printWeightTag() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      let query = {
        pid: this.goodsItem.id,
        o_num: this.goodsNetWeight,
      }
      this.$api.printTag(query).then(async res => {
        if (res.code == 1) {
          let obj = Object.assign({}, res.data, {
            fact_price: this.$bigN(res.data.fact_price).div(100).toFixed(2),
            produce_time: this.befor_produce ? formatTime(new Date(), 'yyyy年MM月dd日') : '',
            bar_code_img: '',
            type: 5,
            printType: '1', // 打印类型， 0 为普通 1为预包装
            total_price: this.goodsWeightPrice,
            weight: this.weightConversion,
            quality: this.befor_quality ? res.data.quality : '',
          })
          let print_data = {
            type: 5,
            good_list: [obj]
          }
          this.$printFunc.printLabelReceipt(print_data);
          this.$toast.clear();
          setTimeout(() => {
            this.openGoodsWeightDialogNew();
          }, 300);
        }
      })
    },
    confirmScale() {
      let query = {
        name: this.shop.name,
        tel: this.shop.tel,
        xq: this.shop.xq || '',
        workTimeS: this.shop.workTimeS,
        workTimeE: this.shop.workTimeE,
        scale_type: this.choose_scale,
        box_type: this.shop.box_type,
      }
      this.$api.dt_updateShopInfo(query).then(async (res) => {
        if (res.code == 1) {
          this.$toast("保存成功，门店设置已更新");
          this.getShopInfo();
          if (!this.scale_type || this.scale_type != this.choose_scale) {
            if (this.choose_scale == '1') {
              getScaleDateHcd();
            } else if (this.choose_scale == '4') {
              getScaleData();
            } else if (this.choose_scale == '2') {
              await connectSunMiScaleService();
              setTimeout(() => {
                sunMiScaleDataInfo();
              }, 500);
              getScaleDateSm();
            } else if (this.choose_scale == '5') {
              getScaleDataRd();
            }
          }
          localStorage.setItem('scale_type', this.choose_scale);
          setTimeout(() => {
            this.openGoodsWeightDialogNew();
          }, 300);
          this.closeScale();
        } else {
          this.$toast(res.msg || "修改门店信息失败");
        }
      });
    },
    openChooseScale() {
      this.choose_scale = this.scale_type;
      this.show_choose_scale = true;
    },
    closeScale() {
      this.show_choose_scale = false;
    },
    getShopInfo() {
      this.$api.dt_getShopInfo().then((res) => {
        if (res.code == 1) {
          this.$store.commit("setShopInfo", res.data);
          this.scale_type = res.data.scale_type.toString() || '';
          let tag_print_set = {};
          if (res.data.tag_print_set) {
            tag_print_set = JSON.parse(res.data.tag_print_set);
          }
          this.befor_produce = tag_print_set.befor_produce == 1;
          this.befor_quality = tag_print_set.befor_quality == 1;
        }
      });
    },
    codeInputFocus() {
      if (!this.is_show_weight && !this.isShowSearch && !this.vipModal
        && !this.show_change_price && !this.show_custom_good && !this.$store.state.is_change_model) {
        this.$nextTick(() => {
          this.$refs.again.focus();
        }, 100);
      }
    },
    confirmMember(index) {
      this.choose_index = index;
      if (this.choose_index == -1) {
        this.$toast('请选择会员');
        return;
      }
      this.$store.commit('setVipInfo', { ...this.vipInfoShow[this.choose_index] });
      this.vipModal = false;
      this.codeInputFocus();
    },
    openMemModal() {
      this.vipModal = true;
      this.vipInfoShow = [];
      this.vipTel = this.vipInfo.mobile;
      this.choose_index = -1;
      this.is_show_notdata = false;
      this.$nextTick(() => {
        this.$refs.membercodeinput.focus();
        this.$refs.membercodeinput.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.membercodeinput.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    // 弹窗开启
    async openGoodsWeightDialogNew() {
      console.log('localStorage.nowDevice', localStorage.nowDevice);
      console.log('this.$store.state.mtScale', this.$store.state.mtScale);
      this.isInApp = localStorage.nowDevice;
      if (localStorage.nowDevice == 'android') {
        console.log('this.scale_type', this.scale_type);
        if (!this.scale_type) {
          this.$toast('未连接电子秤');
          return;
        }
        if (this.scale_type == '1') {
          initHcdElectronic('hcdconnect');
        } else if (this.scale_type == '4') {
          initScale('connect');
        } else if (this.scale_type == '2') {
          await connectSunMiScaleService();
          setTimeout(() => {
            sunMiScaleDataInfo();
          }, 500);
        } else if (this.scale_type == '5') {
          initRdElectronic('rdconnect');
        }
      } else if (localStorage.nowDevice == 'mt_android' && this.$store.state.mtScale && this.$store.state.mtScale.code == 1) {
        this.priceC = priceUnitConversion(this.goodsItem.fact_price, this.goodsItem.dw);
        getScaleInfo(this.priceC);
        getScaleDataMt();
      }
      // getThisWeightDetail();
      // console.log('weightDetail', this.weightDetail, this.$store.state.weightDetail);
      this.getPrice();
    },
    // 弹窗关闭
    goodsWeightDialogCloseNew() {
      if (localStorage.nowDevice == 'android') {
        if (this.scale_type == '1') {
          initHcdElectronic('hcdclose');
        } else if (this.scale_type == '4') {
          initScale('close');
        } else if (this.scale_type == '2') {
          sunMiClose();
        } else if (this.scale_type == '5') {
          initRdElectronic('rdclose');
        }
      } else if (localStorage.nowDevice == 'mt_android') {
        removeScaleListener();
      }
    },
    // 称重商品计算价格
    getPrice() {
      // console.log('000000', this.is_show_weight);
      // 0 稳定 1 不稳定
      // this.weightDetail.grossWeight > 0 ? (this.radio2 = 2) : (this.radio2 = false)
      if (this.is_show_weight) {
        // const price = this.choosenFood.dis_price && this.choosenFood.dis_price > 0 ? this.choosenFood.dis_price : this.choosenFood.price;
        // this.goodsWeightTotalPrice = parseFloat((price * 1000) * (this.weightConversion * 1000) / 1000000).toFixed(2);
        this.goodsWeightTotalPrice = this.goodsNetWeight ? this.$bigN(this.goodsItem.fact_price).times(this.weightConversion).toNumber().toFixed(2) : '0';
        this.goodsWeightPrice = this.goodsWeightTotalPrice;
      } else {
        this.radio1 = false;
        this.radio2 = false;
        this.goodsWeightTotalPrice = '0.00';
        this.goodsWeightPrice = this.goodsWeightTotalPrice;
      }
    },
    handleWeightChange() {
      this.weightChange = true;
    },
    // 清空电子称重量
    clearWeightNew(type) {
      if (localStorage.nowDevice != 'android' && localStorage.nowDevice != 'mt_android') {
        this.$toast('暂无此功能，请手动点击收银秤上去皮或置零按钮')
        return
      }
      if (type == 1) { // 去皮
        this.isShowPeelLoading = true;
        setTimeout(() => {
          this.isShowPeelLoading = false;
        }, 400);
        if (localStorage.nowDevice == 'android') {
          if (this.scale_type == '1') {
            initHcdElectronic('hcdremovepeel');
          } else if (this.scale_type == '4') {
            peelScale();
          } else if (this.scale_type == '2') {
            sunMiTurnTare();
            sunMiScaleDataInfo();
          } else if (this.scale_type == '5') {
            initRdElectronic('rdremovepeel');
          }
        } else if (localStorage.nowDevice == 'mt_android') {
          doTaring();
          removeScaleListener();
          getScaleInfo(this.priceC);
          getScaleDataMt();
        }
        this.radio2 = 2;
        this.radio3 = false;
        this.weightChange = false;
      } else { // 置零
        this.isShowZeroLoading = true;
        setTimeout(() => {
          this.isShowZeroLoading = false;
        }, 400);
        if (localStorage.nowDevice == 'android') {
          if (this.scale_type == '1') {
            initHcdElectronic('hcdturnzero');
          } else if (this.scale_type == '4') {
            zeroSettScale();
          } else if (this.scale_type == '2') {
            sunMiTurnZero();
            sunMiScaleDataInfo();
          } else if (this.scale_type == '5') {
            initRdElectronic('rdturnzero');
          }
        } else if (localStorage.nowDevice == 'mt_android') {
          doZeroing();
          removeScaleListener();
          getScaleInfo(this.priceC);
          getScaleDataMt();
        }
        this.radio2 = false;
        this.radio3 = 3;
        this.weightChange = false;
      }
    },
    setInputWeight() {
      this.goodsNetWeight = this.weightDetail.weight ? parseFloat(this.weightDetail.weight).toFixed(3) : 0;
    },
    // 拉取商品列表
    getSearchGoodsList() {
      let query = {
        shop_id: localStorage.getItem("shop_id"), // 门店ID
        c_id: 0, // 分类ID -1获取平台全部商品 0没有分类id则是获取全部门店商品 大于0是获取指定分类下面的商品
        // c_id: 1,
        param: this.params.goodsName,
        page: this.params.page,
        limit: this.params.limit,
        flag: 1,
      };
      this.$api.goodsList(query).then((res) => {
        if (res.code == 1) {
          this.searchDishList = res.data.list || [];
          this.search_total = res.data.count || 0;
        }
      });
    },
    closeSearch() {
      this.isShowSearch = false;
      this.params.goodsName = '';
      this.params.page = 1;
      this.searchDishList = [];
      this.search_total = 0;
      this.codeInputFocus();
    },
    openSearch() {
      this.params.goodsName = '';
      this.params.page = 1;
      this.searchDishList = [];
      this.search_total = 0;
      this.isShowSearch = true;
      this.$nextTick(() => {
        this.$refs.mn_codeinput.focus();
      }, 100);
    },
    handleSearch() {
      if (this.params.goodsName == '') {
        this.$toast('请输入商品名称搜索');
        return;
      }
      this.params.page = 1;
      this.getSearchGoodsList();
    },
    pageChangeSearch() {
      this.getSearchGoodsList();
    },
    pageChange() {
      this.getGoodsList();
    },
    // 禁止键盘弹起
    noKeybord(e) {
      e.target.setAttribute("readonly", true);
      setTimeout(() => {
        e.target.removeAttribute("readonly");
      }, 100);
    },
    // 小数乘
    Mul(num1, num2) {
      return accMul(num1, num2);
    },
    getTouchStart() {
      clearTimeout(this.sufu);
      this.sufu = setTimeout(function () {
        //  打开钱箱
        if (typeof KissSunmiPrint !== "undefined") {
          KissSunmiPrint.myOpenDrawer([])
            .then(function (msg) {
              console.log("============================1" + msg);
            })
            .catch(function (error) {
              console.log("============================2" + error);
            });
        }
      }, 2000);
    },
    getTouchEnd() {
      clearTimeout(this.sufu);
    },
    // 挂单后回显购物车数据
    async showCartList() {
      if (!this.areCancellOrderNum) return;
      let res = await this.$api.getHang({
        order_num: this.areCancellOrderNum,
      });
      this.$store.commit("updateAreCancellOrderNum", "");
      if (res.code === 1) {
        this.$store.commit("updateAreCancellOrderNum", "");
        console.log(res, "购物车回显数据");
        res.data.reverse();
        // 回显购物车必须先回显扫码唯一编码
        this.swee_code = res.data[0].swee_code;
        this.$store.commit("undateCurrentSwee_code", this.swee_code); // 更新唯一编码付款
        // 配置回显购物车的数据格式
        let arr = [];
        res.data.forEach(item => {
          arr.push(Object.assign({}, item, {
            code: item.swee_code,
            payCount: item.num * 1,
            fact_price: item.fact_price * 100,
          }))
        });
        this.$store.commit("updateShopCart", arr);
        console.log(arr, "arr");
      }
    },
    // 购物车操作
    changeCart(bar_code, at, pro_id) {//传了pro_id表示不是扫码加入的，优先取pro_id
      if (this.swee_code) {
        this.t_order = 2;
      } else {
        this.t_order = 1;
      }
      this.$api
        .sweepCode({
          ...pro_id ? { pro_id } : { bar_code }, // 商品条码/商品id
          t: this.t_order, // 1新的订单，2已开始订单
          swee_code: this.swee_code, // 返回的唯一编码
          at, // 1增加,2减少
          weight: this.goodsNetWeight, //商品重量
        })
        .then((res) => {
          if (res.code === 1) {
            // 如果扫码的话，有多个商品，返回的结果是数组，需要弹框选择某个商品
            if (Array.isArray(res.data)) {
              this.chooseGoodsList = res.data;
              this.show_choose_goods = true;
              this.$toast.clear();
              setTimeout(() => {
                this.is_adding = false;
                this.$nextTick(() => {
                  this.$refs.again.focus();
                }, 100);
              }, 100);
              return;
            }

            const show_order = res.data.products.show_order || [];
            this.$store.commit("updateShopCart", show_order); //更新购物车数据
            if (at == 1) {
              this.swee_code = res.data.code; // 商品的唯一编码

              this.scanPro = res.data;
            } else if (at == 2) {
              if (show_order.length == 0) {
                this.swee_code = undefined;
              }
            }
            this.$store.commit("undateCurrentSwee_code", this.swee_code); // 设置唯一编码
            this.is_show_weight = false;
            this.scrollCart();
            this.$toast.clear();
            setTimeout(() => {
              this.is_adding = false;
              this.$nextTick(() => {
                this.$refs.again.focus();
              }, 100);
            }, 100);
          } else {
            console.log('000000000');
            setTimeout(() => {
              this.is_adding = false;
              this.$nextTick(() => {
                this.$refs.again.focus();
              }, 100);
            }, 100);
          }
        }).catch(() => {
          console.log('0909999999');
          setTimeout(() => {
            this.is_adding = false;
            this.$nextTick(() => {
              this.$refs.again.focus();
            }, 100);
          }, 100);
        });
    },
    scrollCart() {
      // console.log('this.goodsItem', this.goodsItem);
      // console.log('this.shopCartList', this.shopCartList);
      if (this.shopCartList.length <= 0) {
        return;
      }
      this.$nextTick(() => {
        if (this.$refs[`cart_${this.goodsItem.id}`]) {
          // this.$refs[`cart_${this.goodsItem.id}`][0].scrollIntoView();
          // console.log(this.$refs[`cart_${this.goodsItem.id}`][0].offsetTop);
          const top = this.$refs[`cart_${this.goodsItem.id}`][0].offsetTop - 90;
          this.$refs.cart_box.scrollTo({
            'top': top,
            'behavior': 'smooth'
          })
        }
      }, 100);
    },
    // 扫描商品添加到购物车
    getProByCode(bar_code, at, e) {
      e.target.setAttribute("readonly", true);
      setTimeout(() => {
        e.target.removeAttribute("readonly");
      }, 100);
      this.proCode = undefined; // 清空扫码框
      if (this.is_adding || !bar_code || bar_code == '') {
        return;
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      this.is_adding = true;
      this.changeCart(bar_code, at);
    },
    // 点击右侧商品添加购物车
    setShopCart(element, at) {
      console.log("%c Line:1199 🍆 element", "color:#ea7e5c", element);
      if (element.pro_type == 1 && element.stock == 0) {
        Toast("当前商品暂无库存");
        return;
      }
      if (!this.selectGoodList) return;
      this.goodsItem = element;
      this.goodsItem.bar_code = element.bar_code;
      if (element.dw == 'kg' || element.dw == "斤" || element.dw == '克' || element.dw == '千克') {
        this.addKgBtnIndex = false;
        this.goodsItem = element;
        this.goodsItem.bar_code = element.bar_code;
        this.setInputWeight();
        this.weightChange = false;
        this.is_show_weight = true;
        // this.goodsWeightDialog = true;
      } else this.changeCart(element.bar_code, at, element.id);
    },
    // 点击添加按钮增加购物车
    async addNum(element, index) {
      this.goodsItem = element;
      this.goodsItem.name = element.g_name;
      this.goodsItem.bar_code = element.bar_code;
      if (element.dw == 'kg' || element.dw == "斤" || element.dw == '克' || element.dw == '千克') {
        this.addKgBtnIndex = index;
        this.goodsItem = element;
        this.goodsItem.name = element.g_name;
        this.goodsItem.bar_code = element.bar_code;
        this.goodsItem.fact_price = this.$bigN(element.fact_price).div(100);
        this.setInputWeight();
        this.weightChange = false;
        this.is_show_weight = true;
        // this.goodsWeightDialog = true;
      } else {
        // 点击新增/减少按钮时，此时的商品id为g_id
        this.changeCart(element.bar_code, 1, element.g_id);
      }
    },
    // 点击减少按钮减少购物车数量
    async plusNum(element) {
      this.changeCart(element.bar_code, 2, element.g_id);
    },
    confirmPopDialog(data) {
      this.changeCart(data.data.bar_code, 2, data.data.g_id);
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog(element) {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = element;
      this.showDialog = true;
    },
    // 删除购物车商品或判断添加按钮超过库存
    deleteGoods(element, index, e) {
      // 判断是否超出库存
      if (e == "plus") {
        Toast("超出库存数量啦");
        return;
      }
      this.openDialog(element);
    },
    // 清空购物车
    clearlist() {
      this.$store.commit("undateCurrentSwee_code", ""); //清空唯一编码
      this.$store.commit("updateShopCart", []); // 清空购物车
      this.swee_code = undefined; // 重置唯一编码
      this.$store.commit('setVipInfo', {}) // 清空会员选择
    },
    // 确认重量
    confirmDet() {
      // console.log('this.goodRealWeight', this.goodRealWeight);
      if (!this.goodRealWeight || this.goodRealWeight <= 0 || isNaN(this.goodRealWeight))
        return Toast("请称重后再进行确认！");
      this.changeCart(this.goodsItem.bar_code, 1, this.goodsItem.id || this.goodsItem.g_id);
    },
    skinChange() {
    },
    // 弹窗开启
    openGoodsWeightDialog() {
      // 打开弹窗时连接电子称
      if (typeof KissSunmiPrint !== "undefined") {
        this.$scaleService
          .connectScaleService()
          .then((msg) => {
            this.$scaleService.getScaleDataKeepAlive(); // 连续获取电子秤净重、状态、数值返回
            console.log("============================连接成功" + msg);
          })
          .catch((err) => {
            Toast("电子秤未连接！");
            console.log("============================连接失败" + err);
          });
      } else {
        Toast("无法找到电子秤");
      }
    },
    // 弹窗关闭
    goodsWeightDialogClose() {
      if (typeof KissSunmiPrint !== "undefined") {
        this.$scaleService
          .onDestroy()
          .then((msg) => {
            console.log("============================断开成功" + msg);
          })
          .catch((err) => {
            console.log("============================断开失败" + err);
          });
      }
    },
    // 清空电子称重量
    clearWeight() {
      if (typeof KissSunmiPrint !== "undefined") {
        this.$scaleService
          .myZeros()
          .then((msg) => {
            this.$scaleService.getScaleDataKeepAlive(); // 连续获取电子秤净重、状态、数值返回
            console.log("============================清空成功" + msg);
          })
          .catch((err) => {
            console.log("============================清空失败" + err);
          });
      }
    },
    // 拉取商品分类列表
    getCategoryList() {
      return new Promise((resolve, reject) => {
        this.$api
          .goodsType({
            shop_id: localStorage.getItem("shop_id"),
          })
          .then((res) => {
            if (res.code == 1) {
              this.categoryList = res.data.list || [];
              this.categoryId =
                res.data.list.length > 0 ? res.data.list[0].id : "";
              this.category_name = res.data.list.length > 0 ? res.data.list[0].name : "";
              resolve();
            } else {
              reject(res.msg);
            }
          })
          .catch((error) => {
            console.log("首页拉取商品分类 error：", error);
            reject();
          });
      });
    },
    // 拉取商品列表
    getGoodsList() {
      this.selectGoodList = false;
      let query = {
        shop_id: localStorage.getItem("shop_id"), // 门店ID
        c_id: this.categoryId, // 分类ID -1获取平台全部商品 0没有分类id则是获取全部门店商品 大于0是获取指定分类下面的商品
        // c_id: 1,
        param: this.params.goodsName,
        page: this.params.page,
        limit: this.params.limit,
        flag: 1,
      };
      this.$api.goodsList(query).then((res) => {
        if (res.code == 1) {
          this.goodsList = res.data.list;
          this.selectGoodList = true;
          this.total = res.data.count;
        }
      });
    },
    // 跳转至新增商品
    goToManageProducts() {
      this.$router.push({
        path: "/manageProducts",
        query: { name: "manageProducts" },
      });
    },
    closeDet() {
      this.showdet = false;
    },
    // 跳转至结算界面
    goTosettlement() {
      if (this.shopCartList.length < 1) {
        return;
      }
      this.payMoney()
      // },
      // confirmPay(e) {
      //   this.$api
      //     .getMemberByPhone({ phone: this.paymentCode || undefined })
      //     .then((res) => {
      //       if (res.code == "1") {
      //         this.list = res.data;
      //         this.money = (this.totalPrice * res.data.discount) / 100;
      // this.change(res.data.list[this.active].id);
      //   }
      // });
      // this.$api.getMemberCardList().then((res) => {
      //   this.actions1 = res.data.list.map((item) => {
      //     return { name: item.card_name, id: item.id };
      //   });
      // });
    },
    payMoney() {
      this.$router.push({
        path: "/settlement",
        query: {
          name: "settlement",
          type: 2,
          id: this.list.id || undefined,
          card_name: this.list.name || undefined,
          discount: this.list.discount || undefined,
          yue: this.list.balance || undefined,
          status: this.list.status == 1 ? true : false,
        },
      });
    },
    // 跳转至挂单页面
    areCancell() {
      this.$api
        .dt_placeAnOrder({
          t: 1,
          swee_code: this.swee_code,
          // total_price: this.totalPrice,
        })
        .then((res) => {
          if (res.code != 1) return;
          console.log(res, "生成挂单订单");
          this.$store.commit("updateShopCart", []);
          this.$store.commit("undateCurrentSwee_code", "");
          this.swee_code = "";
          // this.$router.push({
          //   path: "/areCancell",
          //   query: { name: "areCancell" },
          // });
        });
    },
    // 跳转至取单页面
    gotoTakeOrders() {
      this.$router.push({
        path: "/areCancell",
        query: { name: "toTakeOrders" },
      });
    },
    // 切换分类
    chooseClassify(item) {
      this.params.page = 1;
      this.categoryId = item.id;
      this.category_name = item.name;
      this.getGoodsList();
    },
    // 关闭弹窗
    closePop() {
      this.menuopen = false;
    },
    // 弹窗关闭按钮
    handlePopOpen(e) {
      this.menuopen = e;
    },
    async vipTelSearch() { // 手机号搜索vip
      if (!this.vipTel || this.vipTel == '') {
        this.$toast('输入手机号或姓名搜索');
        return;
      }
      if (this.vipTel != '') {
        const code_cont = this.vipTel.replace(/[|]/g, "|")
          .replace(/[丨]/g, "|")
          .replace(/[｜]/g, "|");
        console.log('code_cont', code_cont);
        let cont_arr = code_cont.split('|');
        console.log(cont_arr[0], cont_arr[1], parseInt((new Date().getTime() - 30000) / 1000));
        if (cont_arr[1] > 0 && cont_arr[1] < parseInt((new Date().getTime() - 30000) / 1000)) {
          this.vipTel = '';
          this.$toast('会员码已过期，请重新打开小程序出示会员码');
          return;
        }
        this.vipTel = cont_arr[0];
      }
      let res = await this.$api.dt_getMemberList({
        content: this.vipTel,
        status: 1,
      })
      if (res.code === 1) {
        this.vipInfoShow = res.data.data.list;
        this.is_show_notdata = this.vipInfoShow.length == 0;
      } else {
        this.vipInfoShow = [];
        this.is_show_notdata = true;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.custom-good-modal {
  .choose-scle-box {
    margin: 4rem !important;
  }
  .custom-input {
    height: 8rem;
    border: 0.2rem solid #dedede;
    border-radius: 0.8rem;
    padding: 0 2rem;
    font-size: 2.6rem;
    width: 80%;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}
.change-price-modal {
  .choose-scle-box {
    margin: 4rem !important;
  }
  .di-box {
    display: flex;
    align-items: center;
    .dilabel {
      font-size: 2.4rem;
    }

    .diinput {
      height: 6.2rem;
      border: 0.2rem solid #dedede;
      border-radius: 0.8rem;
      margin-left: 2.4rem;
      padding: 0 2rem;
      font-size: 2.4rem;
      flex: 1;
    }
  }
}
.choose-scale-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content-box {
    width: 72rem;
    max-height: 88vh;
    height: fit-content;
    border-radius: 0.8rem;
    background-color: #fff;
  }
  .close {
    width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    z-index: 10;
  }
  .wrapper {
    padding: 0rem 3.5rem;
    position: relative;

    .title {
      padding-top: 2.5rem;
      border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8.5rem;
    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }

  .choose-goods-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .choose-scle-box {
    margin: 4rem 10rem;
    .scale-radio {
      margin-bottom: 2.7rem;
      font-size: 2.4rem;
    }
    .clear-scale {
      font-size: 2.1rem;
      color: #ffffff;
      border: 0;
      padding: 0 1.6rem;
      height: 5rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      background: #1588f5;
    }
  }
}
.nullStyle2 {
  padding-top: 4rem;
  text-align: center;
  img {
    margin: 1.5rem auto 0;
    width: 22rem;
    height: 18rem;
  }
}
.chengzhong-popups {
  width: 82rem;
  //height: 48.7rem;
  background: #ffffff;
  border-radius: 0.8rem;
  // text-align: -webkit-center;
  padding: 0 5rem;

  .system_title {
    display: flex;
    align-items: center;
    text-align: center;
    //justify-content: flex-end;
    //margin: 0 4.6rem 3.1rem 4.6rem;
    height: 8rem;
    border-bottom: 0.15rem solid #cccccc;
    font-size: 2.8rem;
    font-weight: 700;
    color: #333333;
    position: relative;
    .scale-cont-box {
      position: absolute;
      left: 0;
    }
    img {
      width: 3rem;
      position: absolute;
      right: 1rem;
      // height: 4.3rem;
      // text-align: end;
      //margin-left: 29.4rem;
    }
  }

  .cheng-status {
    padding: 4rem 0;
    border-bottom: 0.15rem solid #cccccc;
  }

  .scale {
    //display: flex;
    //justify-content: space-around;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    font-size: 2.8rem;
    margin-top: 3rem;

    .scale-con {
      //text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .scale-con-content {
        width: fit-content;
      }
      .text {
        text-align: right;
      }
      .num {
        text-align: right;
        font-size: 4rem;
        margin-top: 3rem;

        input {
          width: 8rem;
          text-align: right;
        }
      }
    }
  }
  .scale-price {
    // display: flex;
    // justify-content: space-around;
    font-size: 2.6rem;
    margin: 5rem 0;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;
    }
    .scale-label {
      width: 15rem;
      text-align: right;
    }
    .scale-input {
      border-bottom: 0.15rem #cccccc solid;
    }
  }

  .bottom-btn {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }

  .but {
    color: #fff;
    width: 15.5rem;
    height: 7rem;
    border-radius: 0.8rem;
    font-size: 2.4rem;
    line-height: 9rem;
    background: #1588f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }
}
.search-good-box {
  background: #fff;
  flex: 1;
  margin-right: 1.2rem;

  .screeninput {
    border-radius: 0.8rem;
    padding-left: 2.5rem;
    width: 48rem;
    height: 6.8rem;
    border: 0.2rem solid #dedede;
    font-size: 2.4rem;
    color: #333;
  }
  .screeninput::placeholder {
    font-size: 2.4rem;
  }

  .screenbtn {
    background: #1588f5;
    font-size: 2.4rem;
    color: #fff;
    height: 6.8rem;
    padding: 0 3rem;
    border-radius: 0.8rem;
    margin: 0 0 0 3rem;
  }
  .index_list {
    height: calc(100% - 32rem) !important;
    grid-template-columns: repeat(4, 23%) !important;
    grid-gap: 2rem 2.5rem !important;
  }
}
.left-up-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.upposition {
  width: 9rem;
  height: 3.2rem;
  border: 0.1rem solid #fff;
  border-radius: 0.5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.cashOut-gray {
  background: #00000033 !important;
  color: #333333 !important;
}
.scancode-input {
  width: 100%;
  height: 5rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
}
.list_page {
  height: 91rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.List_box {
  display: flex;
  width: 180.3rem;
  font-size: 2.6rem;
  font-weight: bold;
  color: #333333;
  border-bottom: 0.1rem solid #e5e5e5;
  text-align: center;
  .list_name {
    width: 50rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }
  .list_prc {
    width: 15rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }
  .list_num {
    width: 15rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }
}
.v_search {
  height: 10rem;
  background: linear-gradient(270deg, #1588f5 0%, #1588f5 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  .search {
    border-radius: 9rem;
    height: 7rem;
    width: 125rem;
    margin-right: 3rem;
    padding-left: 5rem;
    font-size: 3rem;
  }
}
.text_center {
  text-align: center !important;
}
.index_page {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  .left_box {
    width: 43rem;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .left_heard {
      display: flex;
      height: 10rem;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(270deg, #1588f5 0%, #1588f5 100%);
      padding: 0 3.5rem;
      position: relative;
      img {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
    .left_body {
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0 2.8rem;
      flex: 1;
      &::-webkit-scrollbar {
        width: 0;
      }
      // height: 70rem;
      // padding-bottom: 11rem;
      height: 61rem;
      .vip-info {
        padding: 2rem 0;
        border-bottom: 0.1rem solid #1588f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        .vip-info-left {
          color: #1588f5;
          div {
            padding: 0.5rem;
            text-align: left;
          }
        }
        .vip-handle {
          .van-button {
            height: auto;
            padding: 0.6rem;
            border-radius: 0.8rem;
            font-size: 2rem;
          }
        }
      }
      .goods {
        padding: 2.3rem 0;
        border-bottom: 0.1rem solid #e5e5e5;
        .goods_title {
          font-size: 2.6rem;
          font-weight: bold;
          color: #333333;
          line-height: 3.7rem;
          text-align: left;
          padding-bottom: 0.9rem;
        }
        .change-tag {
          background: #1588f5;
          width: 2.8rem;
          height: 2.8rem;
          text-align: center;
          line-height: 2.8rem;
          border-radius: 0.8rem;
          color: #fff;
          display: inline-block;
          font-size: 1.8rem;
          margin-right: 0.2rem;
        }
        .goods_index {
          display: flex;
          justify-content: space-between;
          .goods_prc {
            font-size: 2.2rem;
            line-height: 3.3rem;
            display: flex;
            align-items: center;
          }
          .goods_steper {
            display: flex;
            .goods_dw {
              display: flex;
              align-items: center;
              font-size: 1.8rem;
              margin-left: 0.5rem;
            }
          }
          .chengzhong-steper /deep/.van-stepper__input {
            width: 7rem;
          }
          .van-stepper {
            .van-stepper__plus {
              color: #fff;
              background-color: #1588f5;
            }
          }
        }
      }
      .createborder {
        border-bottom: 0.1rem solid #fff;
      }
    }
    .left_foot > div {
      height: 15rem;
    }
    .left_foot {
      height: 24rem;
      width: 100%;
      padding-bottom: 3.7rem;
      .foot_prc {
        border-top: 0.1rem solid #e5e5e5;
        padding: 2.4rem 3rem 2rem 3rem;
        text-align: left;
        font-size: 2.4rem;
        color: #333333;
        line-height: 3.3rem;
        font-weight: 400;
        .foot_box_total {
          display: flex;
          justify-content: space-between;
        }
        .foot_txt {
          margin-right: 2rem;
          margin-bottom: 0.5rem;
        }
      }
      .member-price {
        font-size: 2.4rem;
        text-align: right;
      }
      .color_1ccfcf {
        color: #1588f5;
      }
      .btn_box {
        display: flex;
        justify-content: space-between;
        padding: 0 3rem;
        .prc_btn {
          width: 48%;
          height: 5rem;
          border-radius: 3.2rem;
          border: 0.1rem solid #979797;
          color: #999999;
        }
        .border_1ccfcf {
          border: 0.1rem solid #1588f5;
          color: #1588f5;
        }
      }
      .cashOut {
        width: 90%;
        height: 7rem;
        background: linear-gradient(270deg, #1588f5 0%, #1588f5 100%);
        margin-top: 2rem;
        font-size: 2.8rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 4rem;
        border-radius: 0.8rem;
        .pr_30 {
          padding-right: 3rem;
        }
      }
      .border_top {
        // margin-top: 11rem;
        border-top: 0.1rem solid #e5e5e5;
        position: relative;
      }
      .areCancell {
        width: 90%;
        height: 5rem;
        border-radius: 3.2rem;
        border: 0.1rem solid #1588f5;
        font-size: 2.6rem;
        font-weight: 500;
        color: #1588f5;
        // margin-top: 6.5rem;
        position: absolute;
        bottom: 0.5rem;
        left: 5%;
      }
    }
  }
  .right_box {
    position: relative;
    top: 0;
    left: 0;
    width: calc(100% - 43rem);
    height: 100%;
    background: #f8f8f8;
    .tab_list {
      background-color: #fff;
      // display: flex;
      // flex-wrap: wrap;
      width: 21%;
      .tab-list-box {
        overflow-y: scroll;
        height: calc(100% - 8rem);
      }
      &::-webkit-scrollbar {
        width: 0;
      }
      .tab_btn {
        height: 8rem;
        line-height: 1;
        border: none;
        border-radius: 0;
        font-size: 2.2rem;
        color: #333;
        line-height: 8rem;
        border: 0.1rem solid #f5f5f5;
        padding: 0.2rem 1.2rem;
        text-align: left;
      }
      .choose {
        font-weight: bold;
      }
    }

    .good-box {
      width: calc(81% - 3rem);
      margin-left: 1.5rem;
      background: #fff;
      margin-right: 1.5rem;

      .good-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 8rem;
        padding: 0 1.2rem;
        .cate-name {
          font-size: 2.6rem;
          color: #333;
          font-weight: bold;
        }

        .search-btn-tag {
          padding: 0 2rem;
          height: 5.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.8rem;
          color: #fff;
          font-size: 2.4rem;
        }
      }
    }
    .index_list {
      // max-height: 80rem;
      // height: calc(100vh - 41rem);
      flex: 1;
      padding: 1rem 0 4rem 2rem;
      display: grid;
      grid-template-columns: repeat(3, 31.4%);
      // grid-template-rows: repeat(auto-fill, 12rem);
      grid-template-rows: repeat(auto-fill, 16rem);
      grid-gap: 2rem 2%;
      overflow-y: scroll;
      height: calc(100% - 21rem);
      &::-webkit-scrollbar {
        width: 0;
      }
      .index_box {
        position: relative;
        // width: 30.9rem;
        // height: 16rem;
        background: #ffffff;
        box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
        display: flex;
        align-items: center;
        text-align: left;
        border-radius: 0.8rem;
        .index_img {
          min-width: 12rem;
          min-height: 12rem;
          max-width: 12rem;
          max-height: 12rem;
          margin-right: 2rem;
        }
        .goodsName {
          width: 15.9rem;
          font-size: 2rem;
          font-weight: bold;
          color: #333333;
          line-height: 3rem;
          margin-bottom: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .goodsstock {
          font-size: 1.5rem;
          font-weight: 500;
          margin-bottom: 0.3rem;
          //   color: #1588f5;
          line-height: 3rem;
        }
        .goodsPrc {
          font-size: 1.5rem;
          font-weight: 500;
          color: #1588f5;
          line-height: 3rem;
        }
        .icon-con {
          position: absolute;
          bottom: 0;
          right: 0;
          .icon {
            width: 3rem;
            margin-left: 0.4rem;
            /* height: 2rem; */
          }
        }
      }
      .addGoods {
        width: 31.9rem;
        height: 12rem;
        background: #ffffff;
        box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
        margin-bottom: 1.9rem;
        text-align: center;
        font-size: 2.6rem;
        font-weight: 400;
        color: #333333;
        line-height: 12rem;
      }
      .ft_30 {
        font-size: 3rem;
      }
    }

    .pagination-box {
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      width: 100%;
      height: 8rem;
      background: #fff;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.van-stepper {
  display: flex;
  align-items: center;
}

.van-stepper__input {
  width: 5rem;
  font-size: 1.8rem;
}
.vip_s {
  height: 12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  border-radius: 2rem;
  margin-top: 5rem;
  border: 0.2rem solid rgba(187, 187, 187, 100);
  position: relative;
  .name {
    line-height: 6.5rem;
    color: #101010;
    font-size: 3.4rem;
    text-align: left;
  }
  .money {
    float: right;
    color: #3880ff;
    font-size: 4rem;
    font-weight: 700;
  }
}
.vip-modal {
  height: 60vh;
  width: 86rem;
  background: #fff;
  .vip-modal-head {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    .vip-modal-head-title {
      font-size: 3.6rem;
    }
    img {
      position: absolute;
      right: 4rem;
      width: 4.3rem;
    }
  }
  .vip-modal-body {
    height: calc(100% - 19rem);
    overflow-y: scroll;
    .vip-modal-body-input {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        //width: 62.6rem;
        width: 46rem;
        height: 6rem;
        background: #f8f8f8;
        border-radius: 0.8rem;
        //margin: 0 0 2rem 2rem;
        padding-left: 6.3rem;
        font-size: 2.6rem;
        font-weight: 400;
        color: #666666;
        line-height: 3.7rem;
        text-align: left;
      }
      .van-button {
        margin-left: 2rem;
        height: 6rem;
        border-radius: 0.8rem;
      }
    }
    .vip-modal-body-info {
      font-size: 2.6rem;
      margin: 0 10rem;
      line-height: 1.3;
      text-align: left;

      .vipinformation {
        color: #fff;
        padding: 0 2.5rem;
        height: 11rem;
        margin: 2rem 0 1.5rem 0;
        border-radius: 0.8rem;
        background-image: url("../../static/images/member-bgs.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      .choosed1 {
        background-image: url("../../static/images/member-bg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }

    .btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8.5rem;
      .confirm,
      .cancel {
        color: #fff;
        min-width: 20rem;
        height: 6.5rem;
        border-radius: 0.8rem;
        font-size: 2.4rem;
        padding: 0 2.5rem;
      }
      .cancel {
        background: #eaeaea;
        color: #666666;
        margin-right: 10rem;
      }
      .confirm {
        background: #1588f5;
      }
    }
  }
  .vip-modal-bottom {
    height: 8.5rem;

    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1588f5;
  padding: 3rem;
}
.search-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  margin-right: 1rem;
}

.choose-goods-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content-box {
    width: 120rem;
    max-height: 88vh;
    height: fit-content;
    border-radius: 0.8rem;
    background-color: #fff;
  }

  .close {
    width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    z-index: 10;
  }

  .wrapper {
    padding: 0rem 3.5rem;
    position: relative;

    .title {
      padding-top: 2.5rem;
      border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
    }
  }

  .choose-goods-box {
    width: 100%;
  }

  .box-title {
    height: 8rem;
    background: #f8f8f8;
    font-size: 2.6rem;
    font-weight: 900;
    color: #333333;
    display: grid;
    grid-template-columns: repeat(6, 16.666%);
    align-items: center;
  }

  .title-text {
    font-weight: bold;
    text-align: center;
  }

  .goods-item {
    height: 11rem;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    display: grid;
    grid-template-columns: repeat(6, 16.666%);
    align-items: center;
    border-bottom: 0.1rem solid #e5e5e5;
    background: #fff;
    text-align: center;
  }

  .select-btn {
    padding: 1rem;
    background: #1588f5;
    color: #fff;
    border-radius: 0.8rem;
  }
}
</style>
